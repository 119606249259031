import Component from "../component";
import { gsap } from "../../gsap";
import { ScrollTrigger } from "../../gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MEDIA = window.matchMedia("(max-width: 768px)");

export default class Footer extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDom();
    this._handleIntersectChange = this._handleIntersectChange.bind(this);
    this._handleViewportIn = this._handleViewportIn.bind(this);

    const subject = this._createObserverSubject();
    this._createObserver(subject);
  }

  _createObserverSubject() {
    this.dom.component.style.position = "relative";
    const marker = document.createElement("div");
    marker.style =
      "position:absolute;top:-100vh;width:3rem;height:3rem;pointer-events:none;opacity:0";
    this.dom.component.appendChild(marker);
    return marker;
  }

  _createObserver(subject) {
    this._observer = new IntersectionObserver(this._handleIntersectChange, {
      threshold: 0.1, // Trigger when at least 10% is visible
    });
    this._observer.observe(subject);
    this._observer.observe(this.dom.component);
  }

  _handleIntersectChange(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        this._handleViewportIn();
      }
    });
  }

  _handleViewportIn() {
    if (this.dom.video) {
      this.dom.video.play();
    }
  }

  _retrieveDom() {
    this.dom.video = this.dom.component.querySelector("video");
  }

  _resize() {
    let maskImage = "footer.png";
    if (MEDIA.matches) {
      maskImage = "footer-mobile.png";
    }
    if (this.dom.video) {
      this.dom.video.style.maskImage = `url(/app/themes/sp-theme/library/medias/images/masks/${maskImage})`;
    }
  }
}
