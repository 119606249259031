import Component from "../component";
import { gsap } from "../../gsap";
import { ScrollTrigger } from "../../gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const MEDIA = window.matchMedia("(max-width: 1200px)");
export default class Header extends Component {
  currentMenuItemIndex = null;
  currentSubMenuItem = null;

  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.dom.menu = this.dom.component.querySelector(".main-navigation");
    this.dom.burgerButton = this.dom.component.querySelector(".burger");
    this.dom.menuItems = this.dom.menu.querySelectorAll(
      ".main-item:not(.no-sub)",
    );
    this.dom.menuItemsLabels = this.dom.menu.querySelectorAll(
      ".main-item:not(.no-sub) .main-item-label",
    );
    this.dom.homeMenuButton = this.dom.component.querySelector(".home-menu");
    this.dom.homeMenuButtonLabel =
      this.dom.homeMenuButton.querySelector(".home-menu-label");
    this.dom.saveHomeMenuButtonLabel = this.dom.homeMenuButtonLabel.innerHTML;
    this.dom.subMenuItems = this.dom.menu.querySelectorAll(
      ".sub-item:not(.no-sublist):has(>a)",
    );

    this.dom.secondaryNavigation = this.dom.component.querySelector(
      ".secondary-navigation-wrapper",
    );

    // console.log(this.dom.menuItems);
  }

  activeScrolled = () => {
    if (this._lock) return;
    this._scrolled = true;
    this.dom.component.classList.add("scrolled");
  };

  disableScrolled = () => {
    if (this._lock) return;
    this._scrolled = false;
    this.dom.component.classList.remove("scrolled");
  };

  _onMouseEnter = (e) => {
    this.dom.component.classList.add("hover");
  };

  _onMouseLeave = (e) => {
    this.dom.component.classList.remove("hover");
  };

  _toggleMenu = (e) => {
    if (this.isMenuOpen) {
      this._closeMenu();
    } else {
      this._openMenu();
    }
  };

  _openMenu = (e) => {
    this.isMenuOpen = true;
    this.dom.component.classList.add("mobile-menu-open");
    document.body.classList.add("no-scroll");
  };

  _closeMenu = (e) => {
    this.isMenuOpen = false;
    this.dom.component.classList.remove("mobile-menu-open");
    this._desactiveMenuItem();
    this._desactivateSubMenuItem();
    document.body.classList.remove("no-scroll");
  };

  _activeMenuItem = (i, e) => {
    this.currentMenuItemIndex = i;
    this.dom.menuItems[this.currentMenuItemIndex].classList.add("active");

    this.dom.component.classList.add("mobile-submenu-open");
  };

  _desactiveMenuItem = () => {
    if (this.currentMenuItemIndex === null) return;
    this.dom.menuItems[this.currentMenuItemIndex].classList.remove("active");
    this.currentMenuItemIndex = null;

    this.dom.component.classList.remove("mobile-submenu-open");
  };

  _activateSubMenuItem = (i, e) => {
    this.currentSubMenuItem = this.dom.subMenuItems[i];
    this.currentSubMenuItem.classList.add("active");

    this.dom.homeMenuButtonLabel.innerHTML =
      this.dom.menuItemsLabels[this.currentMenuItemIndex].innerHTML;
  };

  _desactivateSubMenuItem = () => {
    if (this.currentSubMenuItem === null) return;
    this.currentSubMenuItem.classList.remove("active");
    this.currentSubMenuItem = null;

    this.dom.homeMenuButtonLabel.innerHTML = this.dom.saveHomeMenuButtonLabel;
  };

  _handleClickBack = () => {
    if (this.currentSubMenuItem !== null) {
      this._desactivateSubMenuItem();
    } else {
      this._desactiveMenuItem();
    }
  };

  disableScrolledSecondary = () => {
    this.dom.secondaryNavigation.classList.remove("scrolled");
  };

  activeScrolledSecondary = () => {
    this.dom.secondaryNavigation.classList.add("scrolled");
  };

  bind() {
    gsap.fromTo(
      this.dom.component,
      {},
      {
        scrollTrigger: {
          start: 0,
          end: "+=20%",
          onEnter: () => this.disableScrolled(),
          onEnterBack: () => this.disableScrolled(),
          onLeave: () => this.activeScrolled(),
          onLeaveBack: () => {
            if (window.scrollY >= 100) this.activeSrolled();
          },
        },
      },
    );

    gsap.fromTo(
      this.dom.component,
      {},
      {
        scrollTrigger: {
          start: 0,
          end: "+=60%",
          onEnter: () => this.disableScrolledSecondary(),
          onEnterBack: () => this.disableScrolledSecondary(),
          onLeave: () => this.activeScrolledSecondary(),
          onLeaveBack: () => {
            if (window.scrollY >= 100) this.activeSrolledSecondary();
          },
        },
      },
    );

    this.dom.burgerButton.addEventListener(
      "click",
      this._toggleMenu.bind(this),
    );
    this.dom.menuItems.forEach((menuItem, index) => {
      menuItem.addEventListener(
        "click",
        this._activeMenuItem.bind(this, index),
      );
      menuItem.addEventListener("mouseenter", this._onMouseEnter.bind(this));
      menuItem.addEventListener("mouseleave", this._onMouseLeave.bind(this));
    });

    this.dom.subMenuItems.forEach((subMenuItem, index) => {
      subMenuItem.addEventListener(
        "click",
        this._activateSubMenuItem.bind(this, index),
      );
    });

    this.dom.homeMenuButton.addEventListener(
      "click",
      this._handleClickBack.bind(this),
    );
  }

  unbind() {
    this.dom.menuItems.forEach((menuItem) => {
      menuItem.removeEventListener("mouseenter", this._onMouseEnter.bind(this));
      menuItem.removeEventListener("mouseleave", this._onMouseLeave.bind(this));
    });
    this.dom.burgerButton.removeEventListener(
      "click",
      this._toggleMenu.bind(this),
    );
    this.dom.menuItems.forEach((menuItem, index) => {
      menuItem.removeEventListener(
        "click",
        this._activeMenuItem.bind(this, index),
      );
    });
    this.dom.subMenuItems.forEach((subMenuItem, index) => {
      subMenuItem.removeEventListener(
        "click",
        this._activateSubMenuItem.bind(this, index),
      );
    });
    this.dom.homeMenuButton.removeEventListener(
      "click",
      this._handleClickBack.bind(this),
    );
  }
}
