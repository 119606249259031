import Component from "../../component";

export default class SmallPopin extends Component {
  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.button = this.dom.component.querySelector(".ct--SmallPopin_close");
  }

  bind() {
    this.button.addEventListener("click", () => {
      this.dom.component.classList.add("popin_hidden");
    });
  }
}
