import Component from "../component";

export default class TrainingForm extends Component {
  isOpen = false;
  currentQuestion = 0;

  selectedTrainingID = null;
  currentSelectionEl = null;

  constructor(...args) {
    super(...args);
    this._retrieveDom();
  }

  _retrieveDom() {
    this.openers = document.querySelectorAll('a[href="#training-form"]');
    this.closers = [
      this.dom.component.querySelector(".closer"),
      this.dom.component.querySelector(".cross"),
    ];

    this.stepText = this.dom.component.querySelector(".current-step");
    this.stepRounds = this.dom.component.querySelectorAll(".step-round");

    this.charaQuestions = this.dom.component.querySelectorAll(
      ".question.character",
    );

    this.charaChoices = new Array(this.charaQuestions.length);
    this.charaQuestions.forEach((_, index) => {
      this.charaChoices[index] = {
        els: this.charaQuestions[index].querySelectorAll(".choice"),
        value: 0,
      };
    });

    this.trainingChoices =
      this.dom.component.querySelectorAll(".choice.training");

    this.nextButtons = this.dom.component.querySelectorAll(".next.button");
    this.prevButtons = this.dom.component.querySelectorAll(".prev.button");

    this.finalNextButton =
      this.dom.component.querySelector(".button.final-next");
    this.finalPrevButton =
      this.dom.component.querySelector(".button.final-prev");

    this.finalQuestion = this.dom.component.querySelector(".question.final");
    this.entertainmentQuestions =
      this.dom.component.querySelector(".entertainment");
    this.designQuestions = this.dom.component.querySelector(".design");

    this.results = this.dom.component.querySelector(".results");
    this.retryButton = this.dom.component.querySelector(".button.retry");
  }

  bind() {
    if (this.charaQuestions.length === 0) {
      return;
    }
    // Show first question
    this.charaQuestions[0].classList.remove("hidden");

    this.openers.forEach((element) => {
      element.addEventListener("click", this.openForm.bind(this));
    });

    this.closers.forEach((element) => {
      element.addEventListener("click", this.closeForm.bind(this));
    });

    this.charaChoices.forEach((charaChoice, index) => {
      charaChoice.els.forEach((answer) => {
        answer.addEventListener(
          "click",
          this.handleAnswerSelection.bind(this, answer, index),
        );
      });
    });

    this.trainingChoices.forEach((choice) => {
      choice.addEventListener(
        "click",
        this.handleTrainingSelection.bind(this, choice),
      );
    });

    this.nextButtons.forEach((nextButton) => {
      nextButton.addEventListener("click", this.loadNextStep.bind(this, 1));
    });
    this.prevButtons.forEach((prevButton) => {
      prevButton.addEventListener("click", this.loadNextStep.bind(this, -1));
    });

    this.finalNextButton.addEventListener(
      "click",
      this.handleFinalNextQuestion.bind(this),
    );
    this.finalPrevButton.addEventListener(
      "click",
      this.handleFinalPrevQuestion.bind(this),
    );

    this.retryButton.addEventListener("click", this.reset.bind(this));
  }

  openForm() {
    document.body.style.overflow = "hidden";
    this.dom.el.classList.remove("hidden");
  }

  closeForm() {
    document.body.style.overflow = "auto";
    this.dom.el.classList.add("hidden");
  }

  setStep(increment) {
    const newStep = parseInt(this.stepText.textContent) + increment;
    this.stepText.textContent = newStep;
    this.stepRounds.forEach((round) => round.classList.remove("active"));
    this.stepRounds[newStep - 1].classList.add("active");
  }

  handleAnswerSelection(el, index) {
    if (this.currentSelectionEl !== null) {
      this.currentSelectionEl.classList.remove("active");
    }
    this.nextButtons[index].classList.remove("disabled");
    el.classList.add("active");
    this.currentSelectionEl = el;
    this.charaChoices[index].value = parseInt(
      this.currentSelectionEl.dataset.type,
    );
  }

  loadNextStep(operator) {
    this.setStep(operator);
    this.charaQuestions[this.currentQuestion].classList.add("hidden");
    this.currentSelectionEl.classList.remove("active");

    this.nextButtons[this.currentQuestion].classList.add("disabled");

    this.currentQuestion = this.currentQuestion + operator;

    if (this.charaQuestions?.[this.currentQuestion] !== undefined) {
      this.charaQuestions[this.currentQuestion].classList.remove("hidden");
    } else {
      const occurences = this.charaChoices.reduce((acc, { value }) => {
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});

      const maxValue = Object.keys(occurences).reduce((a, b) =>
        occurences[a] > occurences[b] ? a : b,
      );

      this.finalQuestion.classList.remove("hidden");

      if (maxValue == 1) {
        this.entertainmentQuestions.classList.remove("hidden");
      } else {
        this.designQuestions.classList.remove("hidden");
      }
    }
  }

  handleTrainingSelection(el) {
    if (this.currentSelectionEl !== null) {
      this.currentSelectionEl.classList.remove("active");
    }
    this.finalNextButton.classList.remove("disabled");
    el.classList.add("active");
    this.currentSelectionEl = el;
    this.selectedTrainingID = parseInt(this.currentSelectionEl.dataset.id);
  }

  handleFinalNextQuestion() {
    this.results.classList.remove("hidden");
    this.finalQuestion.classList.add("hidden");

    this.results
      .querySelector(
        '.training-entry[data-id="' + this.selectedTrainingID + '"]',
      )
      .classList.add("selected");

    this.setStep(1);
  }

  handleFinalPrevQuestion() {
    this.finalQuestion.classList.add("hidden");
    this.entertainmentQuestions.classList.add("hidden");
    this.designQuestions.classList.add("hidden");

    this.finalNextButton.classList.add("disabled");

    this.currentQuestion--;

    this.charaQuestions[this.charaQuestions.length - 1].classList.remove(
      "hidden",
    );

    this.currentStep = this.currentStep - 1;
    this.setStep(-1);
  }

  reset() {
    this.handleFinalPrevQuestion();
    this.dom.component
      .querySelector(".training-entry.selected")
      .classList.remove("selected");

    this.setStep(-1);
    for (let i = 1; i < this.charaQuestions.length; i++) {
      this.loadNextStep(-1);
    }

    this.results.classList.add("hidden");
  }

  unbind() {
    this.openers.forEach((element) => {
      element.removeEventListener("click", this.openForm.bind(this));
    });

    this.closers.forEach((element) => {
      element.removeEventListener("click", this.closeForm.bind(this));
    });
  }
}
